import request from '@/utils/request';

//详情
export function detailIntroduce(data) {
  return request({
    url:'/news/detailIntroduce',
    method : 'post',
    data : data
  })
}

//修改
export function updateIntroduce(data) {
  return request({
    url :'/news/updateIntroduce',
    method : 'post',
    data : data
  })
}
